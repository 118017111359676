import { IAccType, IMerchandiseInventoryReasonsList, IListOfValues, ICustomerType, IGetGateSystems, IDenomList, IGateID, ILanguage, IGetPermissionMandatoryField, IUserList, ISaveDeleteResponse } from 'src/app/Shared/interface';
import {IPagedResults, IPagedResult, ICountry, IState, IListItem, IGetRentRaiseList} from './../Shared/interface';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppInsightsService } from './app-insights.service';
import { ICountryCodes, ITemplateMergeFields, IAccountTypes, ILayoutImages, IColorCodes, IUserPermissionsModel, IGetAuctionMangerFeesChargesList } from '../Shared/interface2';
import { UserService } from './user.service';
import { UserGroupService } from './userGroups.service';
import {AppService} from './app.service';


@Injectable({
    providedIn: 'root',
})

export class GeneralService {
    public baseURL = this.appService.config.apiBaseUrl;
    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    public facilityDateinService;
    constructor(private http: HttpClient,
                private appinsights: AppInsightsService,
                private appService: AppService) { }

                getAuctioneerList(): Observable<any> {
                    return this.http.get<any>(
                        `${this.baseURL}/General/GetAuctioneerList`,
                        { observe: 'response' })
                        .pipe(
                            map(res => {
                                const result = res.body as any;
                                return result;
                            }),
                            catchError(this.handleError)
                        );
                }

              
                getCategoryTypeList(): Observable<any> {
                    return this.http.get<any>(
                        `${this.baseURL}/Category/GetCategoryTypes`,
                        { observe: 'response' })
                        .pipe(
                            map(res => {
                                const result = res.body as any;
                                return result;
                            }),
                            catchError(this.handleError)
                        );
                }
        getFacilitylevellogo(FID): Observable<any> {
            return this.http.get<any>(
                `${this.baseURL}/Prospects/GetFacilitylevellogo?facilityid=${FID}`,
                { observe: 'response' })
                .pipe(
                    map(res => {
                        const result = res.body as any;
                        return result;
                    }),
                    catchError(this.handleError)
                );
        }
        getRentRasieList(): Observable<IGetRentRaiseList[]> {
            return this.http.get<IGetRentRaiseList[]>(
                `${this.baseURL}/General/GetRentRaiseSettings`,
                { observe: 'response' })
                .pipe(
                    map(res => {
                        const result = res.body as IGetRentRaiseList[];
                        return result;
                    }),
                    catchError(this.handleError)
                );
        }

        getRentChangeSettingsList(): Observable<IGetRentRaiseList[]> {

            return this.http.get<IGetRentRaiseList[]>(
                `${this.baseURL}/General/GetRentChangeSettings`,
                { observe: 'response' })
                .pipe(
                    map(res => {
                        const result = res.body as IGetRentRaiseList[];
                        return result;
                    }),
                    catchError(this.handleError)
                );
        }
    getInventoryAdjustmentSetting(FID): Observable<boolean> {
        return this.http.get<boolean>(
            `${this.baseURL}/General/GetInventoryAdjustmentSetting?facilityId=${FID}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const result = res.body as boolean;
                    return result;
                }),
                catchError(this.handleError)
            );
    }

    getImages(typeID): Observable<ILayoutImages[]> {
        return this.http.get<ILayoutImages[]>(
            `${this.baseURL}/General/GetImages?typeId=${typeID}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const result = res.body as ILayoutImages[];
                    return result;
                }),
                catchError(this.handleError)
            );
    }



    getInventoryAdjustmentReasons(): Observable<IMerchandiseInventoryReasonsList[]> {
        return this.http.get<IMerchandiseInventoryReasonsList[]>(
            `${this.baseURL}/General/GetInventoryAdjustmentReasons`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const result = res.body as IMerchandiseInventoryReasonsList[];
                    return result;
                }),
                catchError(this.handleError)
            );
    }

    getTemplateMergeFields(templateId): Observable<ITemplateMergeFields[]> {
        return this.http.get<ITemplateMergeFields[]>(
            `${this.baseURL}/General/GetTemplateMergeFields?templateId=${templateId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const result = res.body as ITemplateMergeFields[];
                    return result;
                }),
                catchError(this.handleError)
            );
    }

    getListOfValues(Id: number): Observable<IListOfValues[]> {
        return this.http.get<IListOfValues[]>(
            `${this.baseURL}/General/GetListOfValues?TypeID=${Id}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const Acctype = res.body as IListOfValues[];
                    return Acctype;
                }),
                catchError(this.handleError)
            );
    }
    getListOfValuesBulk(arr): Observable<any[]> {
        return this.http.post<any[]>(
            `${this.baseURL}/General/GetBulkListOfValuesByTypes`,
            arr,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const lovList = res.body as any[];
                    return lovList;
                }),
                catchError(this.handleError)
            );
    }

    getAllListOfValues(): Observable<any[]> {
        return this.http.get<any[]>(
            `${this.baseURL}/General/GetAllListOfValues`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const lovList = res.body as any[];
                    return lovList;
                }),
                catchError(this.handleError)
            );
    }
    getCountries(): Observable<ICountry[]> {
        return this.http.get<ICountry[]>(
            `${this.baseURL}/General/GetCountries`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const Countries = res.body as ICountry[];
                    return Countries;
                }),
                catchError(this.handleError)
            );
    }
    getAllStates(): Observable<ICountry[]> {
        return this.http.get<ICountry[]>(
            `${this.baseURL}/General/GetAllStates`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const states = res.body as ICountry[];
                    return states;
                }),
                catchError(this.handleError)
            );
    }
    getFacilityUsers(facilityId): Observable<IUserList[]> {
        debugger;
        return this.http.get<IUserList[]>(
            `${this.baseURL}/user/GetAllFacilityUsers?facilityId=${facilityId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const users = res.body as IUserList[];
                    return users;
                }),
                catchError(this.handleError)
            );
    }


    getStates(CountryId): Observable<IState[]> {
        return this.http.get<IState[]>(
            `${this.baseURL}/General/GetStates?countryId=${CountryId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const states = res.body as IState[];
                    return states;
                }),
                catchError(this.handleError)
            );
    }

    getCountry(CountryId): Observable<ICountry> {
        return this.http.get<ICountry>(
            `${this.baseURL}/General/GetCountry?id=${CountryId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const country = res.body as ICountry;
                    return country;
                }),
                catchError(this.handleError)
            );
    }


    getAccType(Id: number): Observable<IAccType[]> {
        return this.http.get<IAccType[]>(
            `${this.baseURL}/General/GetAccountType?accounttypeId=${Id}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const Acctype = res.body as IAccType[];
                    return Acctype;
                }),
                catchError(this.handleError)
            );
    }

    getCustomerTypes(): Observable<ICustomerType[]> {
        const url = `${this.baseURL}/General/GetCustomerTypes`;
        return this.http.get<ICustomerType[]>(url,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const merchandise = res.body as ICustomerType[];
                    return merchandise;
                }),
                catchError(this.handleError)
            );
    }

    getLOVList(Id: number): Observable<IListItem[]> {
        return this.http.get<IListItem[]>(
            `${this.baseURL}/General/GetListOfValues?typeID=${Id}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const LOVListItems = res.body as IListItem[];
                    return LOVListItems;
                }),
                catchError(this.handleError)
            );
    }

    getGateSystems(): Observable<IGetGateSystems[]> {
        return this.http.get<IGetGateSystems[]>(
            `${this.baseURL}/General/GetGateSystems`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const GateSystems = res.body as IGetGateSystems[];
                    return GateSystems;
                }),
                catchError(this.handleError)
            );
    }

    GetAccountTypes(): Observable<IAccountTypes[]> {
        return this.http.get<IAccountTypes[]>(
            `${this.baseURL}/General/GetAccountTypes`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const GateSystems = res.body as IAccountTypes[];
                    return GateSystems;
                }),
                catchError(this.handleError)
            );
    }

    getGateID(facid): Observable<IGateID> {
        return this.http.get<IGateID>(
          `${this.baseURL}/Store/GetFacilityGateId?facilityId=${facid}`,
          { observe: 'response' })
          .pipe(
            map(res => {
              const feature = res.body as IGateID;
              return feature;
            }),
            catchError(this.handleError)
          );
      }

    GetTenantNameDisplayOrder(facid): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/MiscellaneousSetting/GetTenantNameDisplayOrder?facilityId=${facid}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const feature = res.body as any;
                    return feature;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            this.appinsights.logEvent(errMessage);
            return throwError(errMessage);
        }
        return throwError(error || 'Node.js server error');
    }

    getDenominations(): Observable<IDenomList[]> {
        return this.http.get<IDenomList[]>(
            `${this.baseURL}/General/GetDenominationList`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const DenomCount = res.body as IDenomList[];
                    return DenomCount;
                }),
                catchError(this.handleError)
            );
    }

    getLanguagesList(): Observable<ILanguage[]> {
        return this.http.get<ILanguage[]>(
            `${this.baseURL}/Languages/GetLanguages`,
             { observe: 'response' })
            .pipe(
                map(res => {
                    const languageList = res.body as ILanguage[];
                    return languageList;
                }),
                catchError(this.handleError)
            );
    }

    getColorCodes(): Observable<IColorCodes[]>{
        return this.http.get<IColorCodes[]>(
            `${this.baseURL}/General/GetColorCodes`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const unitType = res.body as IColorCodes[];
                    return unitType;
                }),
                catchError(this.handleError)
            );
    }

    public UserPermissions: IUserPermissionsModel;
    public ViewRightFalseMessage: string = "You do not have rights to View";
    public CreateRightFalseMessage: string = "You do not have rights to Create New";
    public EditRightFalseMessage: string = "You do not have rights to Edit";
    public DeleteRightFalseMessage: string = "You do not have rights to Delete"

    getUserPermissionsForModule(functionId) {
        if (this.UserPermissions && this.UserPermissions.Functions) {
            let userfunctionById = this.UserPermissions.Functions.find(fun =>fun.FunctionId == functionId);
            if (userfunctionById && userfunctionById.UserGroupRights) {
                return userfunctionById.UserGroupRights;
            } else {
               return {
                    ViewRight: false,
                    CreateRight: false,
                    EditRight: false,
                    DeleteRight: false
                };
            }
        } else {
            return {
                 ViewRight: false,
                 CreateRight: false,
                 EditRight: false,
                 DeleteRight: false
             };
        }
    }

    GetMandatoryFields(facilityId, compName, formName):Observable <IGetPermissionMandatoryField>{
        return this.http.get<IGetPermissionMandatoryField>(
          `${this.baseURL}/MandatoryFieldSettings/GetMandatoryFields?facilityId=${facilityId}&componentName=${compName}&formName=${formName}` )
          .pipe(
              map(result => {
                  return result as IGetPermissionMandatoryField;
              }),
              catchError(this.handleError)
          );
    }

    GetMandatoryFieldsById(facilityId, formId):Observable <IGetPermissionMandatoryField>{
            return this.http.get<IGetPermissionMandatoryField>(
              `${this.baseURL}/MandatoryFieldSettings/GetMandatoryFieldsById?facilityId=${facilityId}&formId=${ formId}` )
              .pipe(
                  map(result => {
                      return result as IGetPermissionMandatoryField;
                  }),
                  catchError(this.handleError)
              );
        }

        getReportUrl(): Observable<any> {
          return this.http.get<any>(
            `${this.baseURL}/General/GetReportUrl`,
            { observe: 'response' })
            .pipe(
              map(res => {
                return res.body as any;
              }),
              catchError(this.handleError)
            );
        }

        GetPhoneSystemType(): Observable<any> {
          return this.http.get<any>(
            `${this.baseURL}/General/GetPhoneSystemType`,
            { observe: 'response' })
            .pipe(
              map(res => {
                return res.body as any;
              }),
              catchError(this.handleError)
            );
        }

        GetSMSIntervalInMinute(): Observable<any> {
          return this.http.get<any>(
            `${this.baseURL}/General/GetSMSIntervalInMinute`,
            { observe: 'response' })
            .pipe(
              map(res => {
                return res.body as any;
              }),
              catchError(this.handleError)
            );
        }
        getFeesAppliedManually(facilityId): Observable<IGetAuctionMangerFeesChargesList[]> {
            return this.http.get<IGetAuctionMangerFeesChargesList[]>(
                `${this.baseURL}/General/GetFeesAppliedManually?facilityId=${facilityId}`,
                { observe: 'response' })
                .pipe(
                    map(res => {
                        const result = res.body as IGetAuctionMangerFeesChargesList[];
                        return result;
                    }),
                    catchError(this.handleError)
                );
        }
        GetTwilioAccountSid(): Observable<any> {
          return this.http.get<any>(
            `${this.baseURL}/General/GetTwilioAccountSid`,
            { observe: 'response' })
            .pipe(
              map(res => {
                return res.body as any;
              }),
              catchError(this.handleError)
            );
        }

        ExportAllUnitsToGateStorLogixCloud(facilityId: any[]) {
            const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
            let url = '';
                url = `${this.baseURL}/Store/ExportAllUnitToGSI`;
            return this.http.post<ISaveDeleteResponse>(url, facilityId, options)
            .toPromise().then(),
            catchError(this.handleError);
        }

        ExportAllUnitsToGate(facilityId: any[]): Observable<ISaveDeleteResponse> {
            const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
            let url = '';
                url = `${this.baseURL}/Store/ExportAllUnitToGSI`;
            return this.http.post<ISaveDeleteResponse>(url, facilityId, options)
                .pipe(
                    map(res => {
                        return res;
                    }),
                    catchError(this.handleError)
                );
        }

        getDayEndCompletedDate(facilityId): Observable<any> {
            return this.http.get<any>(
                `${this.baseURL}/OpenCloseStore/GetFacilityDayendDate?facilityId=${facilityId}`,
                { observe: 'response' })
                .pipe(
                    map(res => {
                        const result = res.body as any;
                        return result;
                    }),
                    catchError(this.handleError)
                );
        }

        UserFname: string;
        UserLname: string;
        isOpenEdgeFormValid: boolean = false;
        isTdBankFormValid: boolean = false;
        facopsPaymentDistribution: any;
        userGroupPermissions: EventEmitter<any> = new EventEmitter();
        IPBasedCustomerRestrict = false;
        prospectPriorityNotes = false;
        BuildingAreaObj: any;
        FacilityLayoutObj: any;
        UnitsForLayoutDesign = []
        
           createProfileTDBank(obj, tdCredential): Observable<any>{
             let passcode = btoa(`${tdCredential.MerchantId}:${tdCredential.PassCode}`);
            const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': `Passcode ${passcode}`}) };
             //let url = `https://api.na.bambora.com/v1/profiles`
             let url = `${tdCredential.URLforPaymentTD}/profiles`
             return this.http.post<any>(url, obj, options)
               .pipe(
                 map(res => {
                   return res;
                 }),
               );
           }
         
           updateProfileTDBank(obj): Observable<any>{       
          let url = `${this.baseURL}/Payments/TdBankUpdateProfile`;
          return this.http.put<any>(url, obj)
            .pipe(
              map(res => {
                return res;
              }),
            );
        }

        
        threeDSecurePayment(obj,tdCredential): Observable<any>{
        let passcode = btoa(`${tdCredential.MerchantId}:${tdCredential.PassCode}`);
         const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': `Passcode ${passcode}`}) };
          //let url = `https://api.na.bambora.com/v1/payments`
          let url = `${tdCredential.URLforPaymentTD}/payments`
          return this.http.post<any>(url, obj, options)
            .pipe(
              map(res => {
                return res;
              }),
            );
        }
       
        completePreAuthPayment(obj,tdCredential): Observable<any>{
            let passcode = btoa(`${tdCredential.MerchantId}:${tdCredential.PassCode}`);
         const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': `Passcode ${passcode}`}) };
          //const bamboraUrl = `${tdCredential.URLforPaymentTD}/payments`
          let url = `${tdCredential.URLforPaymentTD}/payments/${obj.transactionId}/completions`;
          return this.http.post<any>(url, obj.completion,options)
            .pipe(
              map(res => {
                return res;
              }),
            );
        }

        tDBankPaymentContinue(obj,threeDSessiondata,tdCredential): Observable<any>{ 
            let passcode = btoa(`${tdCredential.MerchantId}:${tdCredential.PassCode}`);     
            const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': `Passcode ${passcode}`}) };  
            let url = `${tdCredential.URLforPaymentTD}/payments/${threeDSessiondata}/continue`       
            return this.http.post<any>(url, obj,options)   
              .pipe(      
                map(res => {   
                  return res;     
                }),    
              );    
          }
        
        checkTDProfilePresentInPortal(uniqueIdentifier, tdCredential): Observable<any>{
           let passcode = btoa(`${tdCredential.MerchantId}:${tdCredential.PassCode}`);
           const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': `Passcode ${passcode}`}) };
           let url = `${tdCredential.URLforPaymentTD}/profiles/${uniqueIdentifier}`
           return this.http.get<any>(url, options)
             .pipe(
              map(res => {
                return res;
                  }),
              );
        }

        IsExistingcustomer  = false;

}
