import { Injectable, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IPaymentSettingsList, IPaymentOrder, IisExist, ISaveDeleteResponse, ISaveModel, ITdPaymentUniqueKey } from '../Shared/interface';
import { IPaymentSettingsDetails, IPaymentMode, IPaymentTransactionTypes, IStore } from '../Shared/interface';
import { AppInsightsService } from './app-insights.service';
import { ItenantUnitLeaseModel, IMissingCCPayments } from '../Shared/interface2';
import { AppService } from './app.service';
import { ECreditCards, EPaymentOrigin, EPaymentTransactionTypes } from '../Shared/enum';
import { CommonMethodService } from '../Shared/common-method.service';



@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    @Output() tdResponseSubject: EventEmitter<any> = new EventEmitter<any>();
    private baseURL = this.appService.config.apiBaseUrl;

    constructor(private http: HttpClient,
        private appinsights: AppInsightsService,
        private appService: AppService,
        private commonMethod: CommonMethodService) { }
    getPaymentPage(): Observable<IPaymentSettingsList[]> {
        return this.http.get<IPaymentSettingsList[]>(
            `${this.baseURL}/PaymentSettings/GetPaymentSettingsList`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const pagedResult = res.body as IPaymentSettingsList[];
                    return pagedResult;
                }),
                catchError(this.handleError)
            );
    }
    getPaymentSettingsDetails(Id: number): Observable<IPaymentSettingsDetails> {
        return this.http.get<IPaymentSettingsDetails>(
            `${this.baseURL}/PaymentSettings/GetPaymentSettingsDetails?id=${Id}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const Details = res.body as IPaymentSettingsDetails;
                    return Details;
                }),
                catchError(this.handleError)
            );
    }
    getpaymentOrder(): Observable<IPaymentOrder[]> {
        return this.http.get<IPaymentOrder[]>(
            `${this.baseURL}/PaymentSettings/GetPaymentOrder`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const payOrder = res.body as IPaymentOrder[];
                    return payOrder;
                }),
                catchError(this.handleError)
            );
    }
    getpaymentSettingsFacilityList(): Observable<IStore[]> {
        return this.http.get<IStore[]>(
            `${this.baseURL}/PaymentSettings/GetPaymentSettingsFacilityList`)
            .pipe(
                map(store => {
                    const storelist = store as IStore[];
                    return storelist;
                }),
                catchError(this.handleError)
            );
    }

    getpaymentModes(): Observable<IPaymentMode[]> {
        return this.http.get<IPaymentMode[]>(
            `${this.baseURL}/PaymentSettings/GetPaymentModes`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const payMode = res.body as IPaymentMode[];
                    return payMode;
                }),
                catchError(this.handleError)
            );
    }

    gettransactionTypes(): Observable<IPaymentTransactionTypes[]> {
        return this.http.get<IPaymentTransactionTypes[]>(
            `${this.baseURL}/PaymentSettings/GetPaymentTransactionTypes`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const transType = res.body as IPaymentTransactionTypes[];
                    return transType;
                }),
                catchError(this.handleError)
            );
    }

    getIsPSNameExist(exist: string): Observable<IisExist> {
        return this.http.get<IisExist>(
            `${this.baseURL}/PaymentSettings/GetPaymentSettingsNameExist?paymentSettingName=${exist}`,
            { observe: 'response' })
            .pipe(
                map(result => {
                    const isExist = result.body as IisExist;
                    return isExist;
                }
                ),
                catchError(this.handleError)
            );
    }


    savePaymentSettings(paymentsettings: IPaymentSettingsDetails): Observable<ISaveDeleteResponse> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = '';
        if (paymentsettings.Id === 0) {
            url = `${this.baseURL}/PaymentSettings/CreatePaymentSettings`;
        } else {
            url = `${this.baseURL}/PaymentSettings/UpdatePaymentSettings`;
        }

        return this.http.post<ISaveDeleteResponse>(url, paymentsettings, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }
    saveautopay(autopaydetails: ItenantUnitLeaseModel): Observable<ISaveModel> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/ModifyRentals/SaveAutoPayDetails`;
        return this.http.post<ISaveModel>(url, autopaydetails, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );

    }

    deletePaymentSettings(psId: number) {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post<boolean>(`${this.baseURL}/PaymentSettings/DeletePaymentSettings?id=${psId}`, options);
    }



    // ------------------------------------------------------- Operation Payments -----------------------------------------------------




    getRCMService(url, request): Observable<any> {
        //const options = { headers: new HttpHeaders({ 'Content-Type': 'application/xml' ,  responseType: 'text/xml'}) };
        return this.http.get<any>(
            `${url}${request}`)
            .pipe(
                map(res => {
                    const responseRCM = res as any;
                    return responseRCM;
                }),
                catchError(this.handleError)
            );
    }

    getCCCredentials(param1): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/Store/GetFacilityCreditCardDetailsById?facilityId=${param1}`)
            .pipe(
                map(res => {
                    const response = res as any;
                    return response;
                }),
                catchError(this.handleError)
            );
    }



    getCNPService(rqsturl, request): Observable<any> {
        const tempobj = {
            Url: rqsturl,
            XmlDoc: request
        };
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post<any>(
            `${this.baseURL}/Payments/GetCNPResponseURL`, tempobj, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    generatePDF(pdfStrings: any) {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let urlVal = `${this.baseURL}/MergePDF/GetMergePDF`;
        return this.http.post(urlVal, pdfStrings, { responseType: 'blob' })
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    generateHashKey(url) {
        const options = { responseType: 'string' };
        let urlVal = `${this.baseURL}/Payments/GetHashString?inputString=${url}`;
        return this.http.get(urlVal, { responseType: 'text' })
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    saveCCTransaction(model): Observable<any> { // For creating Dummy record on initally CC Transaction and get OrderID, Then after CCT
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/Prospects/SaveCreditCardTransaction`;

        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    voidTdTransaction(model, passcode, trnId): Observable<any> { // For creating Dummy record on initally CC Transaction and get OrderID, Then after CCT
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Passcode ${passcode}` }) };
        // options.headers.set('Authorization', `Passcode ${passcode}`);
        let url = `https://api.na.bambora.com/v1/payments/${trnId}/void`;

        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    RefundTdTransaction(model, passcode, trnId): Observable<any> { // For creating Dummy record on initally CC Transaction and get OrderID, Then after CCT

        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Passcode ${passcode}` }) };
        // options.headers.set('Authorization', `Passcode ${passcode}`);
        let url = `https://api.na.bambora.com/v1/payments/${trnId}/returns`;

        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }


    TdDevicePayment(model): Observable<any> { // For creating Dummy record on initally CC Transaction and get OrderID, Then after CCT
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/Payments/TdBankPayment`;

        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    cardOnFilePaymentEdge(model): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/Payments/EdgeExpressCCPayment`;

        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    SaveApprovalForRefundReversal(requestParams): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        if (requestParams.Type == 606) { }
        var url = requestParams.Type == 606 ? `${this.baseURL}/Refund/SaveApprovalForRefund` :
            `${this.baseURL}/PaymentReversal/SaveApprovalForReversal`;
        return this.http.post<any>(url, requestParams, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    TdBankSingleUseTokenPayment(model): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/Payments/TdBankSingleUseTokenPayment`;

        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    TdBankProfilePayment(model): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/Payments/TdBankProfilePayment`;

        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getCCTStatus(cctId) {
        let url = `${this.baseURL}/MakePayment/GetCreditCardTransactionStatus?cctId=${cctId}`;
        return this.http.get(url, { responseType: 'text' })
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetFacilityPaymentModes(facilityId, transactionType): Observable<IPaymentMode[]> {
        return this.http.get<IPaymentMode[]>(
            `${this.baseURL}/PaymentSettings/GetFacilityPaymentModes?facilityId=${facilityId}&transactionType=${transactionType}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const payModes = res.body as IPaymentMode[];
                    return payModes;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            this.appinsights.logEvent(errMessage);
            return throwError(errMessage);
        }
        return throwError(error || 'Node.js server error');
    }

    GetTdPaymentResponse(string: string): Observable<ITdPaymentUniqueKey> {
        return this.http.get<ITdPaymentUniqueKey>(
            `${this.baseURL}/Payments/GetTdPaymentResponse?UniqueKey=${string}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const PaymentDetails = res.body as ITdPaymentUniqueKey;
                    return PaymentDetails;
                }),
                catchError(this.handleError)
            );
    }


    deleteTDBankProfile(facilityId, paymentAccountId): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/Payments/TdBankDeleteProfile?facilityId=${facilityId}&aliasKey=${paymentAccountId}`;
        return this.http.delete<any>(url, options)
            .pipe(
                map(res => {
                    return res;
                }),
            );
    }

    getCresdata(GatewayRes) {
        let url = `${this.baseURL}/Payments/GetCresData?threeDSessiondata=${GatewayRes}`;
        return this.http.get(url, { responseType: 'text' })
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    TdBankVoidPayment(voidPayment, tdCredential): Observable<any> {
        let passcode = btoa(`${tdCredential.MerchantId}:${tdCredential.PassCode}`)
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Passcode ${passcode}` }) };
        let url = `${tdCredential.URLforPaymentTD}/payments/${voidPayment.TransactionId}/void`
        return this.http.post<any>(url, voidPayment, options)
            .pipe(
                map(res => {
                    return res;
                }),
            );
    }

    TdBankReturnPayment(returnPayment, tdCredential): Observable<any> {
        let passcode = btoa(`${tdCredential.MerchantId}:${tdCredential.PassCode}`)
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Passcode ${passcode}` }) };
        let url = `${tdCredential.URLforPaymentTD}/payments/${returnPayment.TransactionId}/returns`
        return this.http.post<any>(url, returnPayment, options)
            .pipe(
                map(res => {
                    return res;
                }),
            );
    }

    getRCMServiceStatus(sessionId, orderId, isCancelRequest): Observable<any> {
        let url = '';
        if (isCancelRequest) {
            url = 'https://localsystem.paygateway.com:21113/RcmService.svc/Update?updateParameters=%2FCancelTransaction%20%2FSessionId%3A' + `${sessionId}`;
        } else {
            url = 'https://localsystem.paygateway.com:21113/RcmService.svc/status?SessionId=' + `${orderId}`;
        }

        return this.http.get<any>(
            `${url}`)
            .pipe(
                map(res => {
                    const responseRCM = res as any;
                    return responseRCM;
                }),
                catchError(this.handleError)
            );
    }

    GetCreditCardTransactionById(cctId) {
        let url = `${this.baseURL}/MakePayment/GetCreditCardTransactionById?cctId=${cctId}`;
        return this.http.get(url, { responseType: 'text' })
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getCNPResponse(credentials, orderId): Observable<any> {
        const tempobj = {
            Url: credentials.QueryPaymentStatusURL,
            XmlDoc: "<REQUEST>" +
                "<XWEBID>" + credentials.XWEBID + "</XWEBID>" +
                "<XWEBTERMINALID>" + credentials.XWEBTERMINALID + "</XWEBTERMINALID>" +
                "<XWEBAUTHKEY>" + credentials.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
                "<TRANSACTIONTYPE>QUERYPAYMENT</TRANSACTIONTYPE> <ORDERID>" + orderId + "</ORDERID>" +
                "<RECEIPTFORMAT>TEXT</RECEIPTFORMAT>" +
                "</REQUEST> "
        };
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post<any>(
            `${this.baseURL}/Payments/GetCNPResponseURL`, tempobj, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    saveACHTransaction(model): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/Prospects/SaveAchTransaction`;
        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetACHResponse(orderId, credential): Observable<any> {
        const req = {
            Url: credential.QueryPaymentStatusURL,
            XmlDoc: "<REQUEST>" +
                "<XWEBID>" + credential.XWEBID + "</XWEBID>" +
                "<XWEBTERMINALID>" + credential.XWEBTERMINALID + "</XWEBTERMINALID>" +
                "<XWEBAUTHKEY>" + credential.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
                "<TRANSACTIONTYPE>QUERYPAYMENT</TRANSACTIONTYPE>" +
                "<ORDERID>" + orderId + "</ORDERID>" +
                "<RECEIPTFORMAT>TEXT</RECEIPTFORMAT>" +
                "</REQUEST>"
        }
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post<any>(
            `${this.baseURL}/Payments/GetCNPResponseURL`, req, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetACHVoid(credential, transId): Observable<any> {
        const returnReq = {
            Url: credential.QueryPaymentStatusURL,
            XmlDoc: "<REQUEST>" +
                "<XWEBID>" + credential.XWEBID + "</XWEBID>" +
                "<XWEBTERMINALID>" + credential.XWEBTERMINALID + "</XWEBTERMINALID>" +
                "<XWEBAUTHKEY>" + credential.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
                "<TRANSACTIONTYPE>CHECKVOID</TRANSACTIONTYPE>" +
                "<TRANSACTIONID>" + transId + "</TRANSACTIONID>" +
                "</REQUEST>"
        }
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post<any>(
            `${this.baseURL}/Payments/GetCNPResponseURL`, returnReq, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetAchRefund(credential, amount, transId): Observable<any> {
        const requestParam = {
            Url: credential.QueryPaymentStatusURL,
            XmlDoc: "<REQUEST>" +
                "<XWEBID>" + credential.XWEBID + "</XWEBID>" +
                "<XWEBTERMINALID>" + credential.XWEBTERMINALID + "</XWEBTERMINALID>" +
                "<XWEBAUTHKEY>" + credential.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
                "<TRANSACTIONTYPE>CHECKCREDIT</TRANSACTIONTYPE>" +
                "<AMOUNT>" + amount + "</AMOUNT>" +
                "<TRANSACTIONID>" + transId + "</TRANSACTIONID>" +
                "<ENTRYCLASS>PPD</ENTRYCLASS>" +
                "<RECEIPTFORMAT>TEXT</RECEIPTFORMAT>" +
                "<BANKACCOUNTTYPE>CHECKING</BANKACCOUNTTYPE>" +
                "</REQUEST>"
        }
        const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
        return this.http.post<any>(`${this.baseURL}/Payments/GetCNPResponseURL`, requestParam, headers)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetCNPCreditVoid(credential, transId, amount): Observable<any> {
        const returnReq = {
            Url: credential.QueryPaymentStatusURL,
            XmlDoc: "<REQUEST>" +
                "<XWEBID>" + credential.XWEBID + "</XWEBID>" +
                "<XWEBTERMINALID>" + credential.XWEBTERMINALID + "</XWEBTERMINALID>" +
                "<XWEBAUTHKEY>" + credential.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
                "<TRANSACTIONTYPE>CREDITVOID</TRANSACTIONTYPE>" +
                "<AMOUNT>" + amount + "</AMOUNT>" +
                "<TRANSACTIONID>" + transId + "</TRANSACTIONID>" +
                "<RECEIPTFORMAT>TEXT</RECEIPTFORMAT>" +
                "</REQUEST>"
        }
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post<any>(
            `${this.baseURL}/Payments/GetCNPResponseURL`, returnReq, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetPaymentTransactionTypeId(paymentType) {
        if (paymentType == EPaymentOrigin.Merchandise) {
            return EPaymentTransactionTypes.Merchandise;
        } else if (paymentType == EPaymentOrigin.Reservation) {
            return EPaymentTransactionTypes.Reservation;
        } else if (paymentType == EPaymentOrigin.Rental) {
            return EPaymentTransactionTypes.MoveIn;
        } else if (paymentType == EPaymentOrigin.Transfer) {
            return EPaymentTransactionTypes.Transfer;
        } else if (paymentType == EPaymentOrigin.MoveOut) {
            return EPaymentTransactionTypes.MoveOut;
        } else if (paymentType == EPaymentOrigin.MakePayment) {
            return EPaymentTransactionTypes.Payment;
        } else if (paymentType == EPaymentOrigin.AuctionManager) {
            return EPaymentTransactionTypes.AuctionManager;
        } else if (paymentType == EPaymentOrigin.ReInstate) {
            return EPaymentTransactionTypes.Reinstate;
        }
    }

    getRefundPaymentTransactionTypeId(refundType) {
        if (refundType == EPaymentOrigin.Merchandise) {
            return EPaymentTransactionTypes.MerchandiseReturn;
        } else if (refundType == EPaymentOrigin.Rental) {
            return EPaymentTransactionTypes.MoveIn;
        } else if (refundType == EPaymentOrigin.Reservation) {
            return EPaymentTransactionTypes.Reservation;
        } else if (refundType == EPaymentOrigin.Transfer) {
            return EPaymentTransactionTypes.Transfer;
        } else if (refundType == EPaymentOrigin.MoveOut) {
            return EPaymentTransactionTypes.MoveOut;
        } else if (refundType == EPaymentOrigin.MakePayment) {
            return EPaymentTransactionTypes.Payment;
        } else if (refundType == EPaymentOrigin.Refund) {
            return EPaymentTransactionTypes.Refund;
        } else if (refundType == EPaymentOrigin.SeperateMerchRefund) {
            return EPaymentTransactionTypes.Refund;
        }
    }

    getTdPaymentCardType(cardType) {
        if (cardType) {
            if (cardType.toLowerCase() == 'am') {
                return ECreditCards.American_Express;
            } else if (cardType.toLowerCase() == 'vi' || cardType.toLowerCase() == 'pv') {
                return ECreditCards.Visa;
            } else if (cardType.toLowerCase() == 'mc' || cardType.toLowerCase() == 'md') {
                return ECreditCards.Master_Card;
            } else if (cardType.toLowerCase() == 'nn') {
                return ECreditCards.Discover;
            } else if (cardType.toLowerCase() == 'di') {
                return ECreditCards.Diners;
            } else if (cardType.toLowerCase() == 'jb') {
                return ECreditCards.JCB;
            }
        } else {
            return ECreditCards.Visa;
        }
    }

    openEdgeCardType(cType) {
        if (cType) {
            cType = cType.replace(/\s/g, '');
            if (cType.toLowerCase() == 'americanexpress') {
                return ECreditCards.American_Express;
            } else if (cType.toLowerCase() == 'visa') {
                return ECreditCards.Visa;
            } else if (cType.toLowerCase() == 'mastercard') {
                return ECreditCards.Master_Card;
            } else if (cType.toLowerCase() == 'discover') {
                return ECreditCards.Discover;
            } else if (cType.toLowerCase() == 'diners') {
                return ECreditCards.Diners;
            } else if (cType.toLowerCase() == 'jcb') {
                return ECreditCards.JCB;
            } else {
                return ECreditCards.Visa;
            }
        } else {
            return ECreditCards.Visa;
        }
    }

    InitiateTDPayment(model, localHostUrl): Observable<any> {
        localHostUrl = localHostUrl.endsWith('\/') ? localHostUrl : localHostUrl + "\/";
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${localHostUrl}api/TdBankPayments/TDTerminalPaymentRequest`;
        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );

    }

    disablePaymentTDForm(valid) {
        let customFormValid = (<HTMLInputElement>document.getElementById('isTDFormValid')).value
        if (customFormValid == 'true') {
            if ((valid.MandatoryFields.find((item) => item.Name == 'tdFName').Required && (valid.FName == '' || !valid.FName)) ||
                (valid.MandatoryFields.find((item) => item.Name == 'tdLName').Required && (valid.LName == '' || !valid.LName)) ||
                (valid.MandatoryFields.find((item) => item.Name == 'tdCity').Required && (valid.City == '' || !valid.City)) ||
                (valid.MandatoryFields.find((item) => item.Name == 'tdAddress').Required && (valid.Address == '' || !valid.Address)) ||
                (valid.MandatoryFields.find((item) => item.Name == 'tdZip').Required && (valid.Zip == '' || !valid.Zip)) ||
                (valid.MandatoryFields.find((item) => item.Name == 'tdCellphone').Required && (valid.Cellphone == '' || valid.CellphoneNotValid)) ||
                (valid.MandatoryFields.find((item) => item.Name == 'tdEmail').Required && (valid.Email == '' || !valid.Email)) ||
                (valid.MandatoryFields.find((item) => item.Name == 'tdState').Required && (valid.StateId == 0 || !valid.StateId)) ||
                (valid.NameOnCard == '' || this.noWhitespaceValidator(valid.NameOnCard))) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public noWhitespaceValidator(control) {
        if ((control !== '') && (control !== null) && (control !== undefined)) {
            const isWhitespace = (control).trim().length === 0;
            const isValid = !isWhitespace;
            return isValid ? null : true;
        }
    }

    getMissingCCPayments(obj): Observable<IMissingCCPayments> {
        return this.http.post<IMissingCCPayments>(
            `${this.baseURL}/Payments/GetMissingCCTransactions`, obj)
            .pipe(
                map(res => {
                    const missingCCPayments = res;
                    return missingCCPayments;
                }),
                catchError(this.handleError)
            );
    }

    CallPayFieldReversal(requestBody) {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post<any>
            (`${this.baseURL}/Payments/PayFieldReversalTransaction`, requestBody, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    mapBillingInfo(customerInfo) {
        const billingIfo = {
         firstName : customerInfo.FirstName ? this.commonMethod.replaceXMLEntity(customerInfo.FirstName) : "",
         middleName : customerInfo.MiddleName ?this.commonMethod.replaceXMLEntity(customerInfo.MiddleName) : "",
         lastName : customerInfo.LastName ? this.commonMethod.replaceXMLEntity(customerInfo.LastName) : "",
         address : customerInfo.AddressOne ? this.commonMethod.replaceXMLEntity(customerInfo.AddressOne) : "",
         companyName : customerInfo.CompanyName ? this.commonMethod.replaceXMLEntity(customerInfo.CompanyName) : "",
         city : customerInfo.City ? this.commonMethod.replaceXMLEntity(customerInfo.City) : "",
         countryCode : customerInfo && customerInfo.CountryCode ? customerInfo.CountryCode :"",
         stateCode : customerInfo && customerInfo.StateCode ? customerInfo.StateCode : "",
         postalCode : customerInfo && customerInfo.PostalCode ? customerInfo.PostalCode : ""
        }
        return billingIfo;
    }
}
