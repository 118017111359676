import { IVendorDetails, IUserGroup, IUserGroupList, IUserGroupModule, ICodeExist, INameExist,
     ISaveDeleteResponse, IUserGroupFeesAndChargesModel} from './../Shared/interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IHoliday, IPagedResults, IPagedResult, IStore } from '../Shared/interface';
import { environment } from 'src/environments/environment';
import { AppInsightsService } from './app-insights.service';
import { IUserPermissionsModel } from '../Shared/interface2';
import {AppService} from './app.service';

@Injectable({
    providedIn: 'root',
})

export class UserGroupService {
    private baseURL = this.appService.config.apiBaseUrl;

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private http: HttpClient,
                private appinsights: AppInsightsService,
                private appService: AppService) { }

    getUserGroupsLists(): Observable<IUserGroupList[]> {
        return this.http.get<IUserGroupList[]>(
            `${this.baseURL}/UserGroups/GetUserGroups`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const UserGroups = res.body as IUserGroupList[];
                    return UserGroups;
                }),
                catchError(this.handleError)
            );
    }

    getDefaultRights(): Observable<IUserGroupModule[]> {
        return this.http.get<IUserGroupModule[]>(
            `${this.baseURL}/UserGroups/GetAllModules`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const UserGroups = res.body as IUserGroupModule[];
                    return UserGroups;
                }),
                catchError(this.handleError)
            );
    }

    getUserGroupsDetail(Id: number): Observable<IUserGroup> {
        return this.http.get<IUserGroup>(
            `${this.baseURL}/UserGroups/GetUserGroupDetails?groupId=${Id}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const grouDetails = res.body as IUserGroup;
                    return grouDetails;
                }),
                catchError(this.handleError)
            );
    }

    updateUserGroups(GroupDetail: IUserGroup): Observable<number> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = '';
        if (GroupDetail.GId === 0) {
            url = `${this.baseURL}/UserGroups/CreateUserGroup`;
        } else {
            url = `${this.baseURL}/UserGroups/UpdateUserGroup`;
        }
        return this.http.post<number>(url, GroupDetail, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }


    getIsGrpCodeCodeExist(Code: string): Observable<ICodeExist> {
        return this.http.get<ICodeExist>(
            `${this.baseURL}/UserGroups/GetIsGroupCodeExist?groupCode=${Code}`,
            { observe: 'response' })
            .pipe(
                map(result => {
                    const isExist = result.body as ICodeExist;
                    return isExist;
                }
                ),
                catchError(this.handleError)
            );
    }

    getIsGrpNameExist(Name: string): Observable<INameExist> {
        return this.http.get<INameExist>(
            `${this.baseURL}/UserGroups/GetIsGroupNameExist?groupName=${Name}`,
            { observe: 'response' })
            .pipe(
                map(result => {
                    const isExist = result.body as INameExist;
                    return isExist;
                }
                ),
                catchError(this.handleError)
            );
    }

    getUserGroupPermissions(Id: number): Observable<IUserPermissionsModel> {
        return this.http.get<IUserPermissionsModel>(
            `${this.baseURL}/UserGroups/GetUserGroupPermissions?userGroupId=${Id}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const grouDetails = res.body as IUserPermissionsModel;
                    return grouDetails;
                }),
                catchError(this.handleError)
            );
    }
    getNewslist(facilityId:number, startDate):Observable<any>{
        return this.http.get<any>(
          `${this.baseURL}/News/GetNewsList?facilityId=${facilityId}&startDate=${startDate}`,
          { observe: 'response' })
          .pipe(
            map(res => {
              const Newslist = res.body as any;
              return Newslist;
            }),
            catchError(this.handleError)
          );

      }

    deleteUserGroups(grpId: number) {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

        return this.http.post<ISaveDeleteResponse>(`${this.baseURL}/UserGroups/DeleteUserGroup?groupId=${grpId}`, options);
    }

    GetActiveFeesAndChargesListForUserGroup(): Observable<IUserGroupFeesAndChargesModel[]>{
        return this.http.get<IUserGroupFeesAndChargesModel[]>(
          `${this.baseURL}/UserGroups/GetActiveFeesAndChargesListForUserGroup`,
          { observe: 'response' })
          .pipe(
            map(res => {
              const list = res.body as IUserGroupFeesAndChargesModel[];
              return list;
            }),
            catchError(this.handleError)
          );
    }

    GetAllowanceItemListForUserGroup(): Observable<IUserGroupFeesAndChargesModel[]>{
        return this.http.get<IUserGroupFeesAndChargesModel[]>(
          `${this.baseURL}/UserGroups/GetAllowanceItemListForUserGroup`,
          { observe: 'response' })
          .pipe(
            map(res => {
              const list = res.body as IUserGroupFeesAndChargesModel[];
              return list;
            }),
            catchError(this.handleError)
          );
    }

    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            this.appinsights.logEvent(errMessage);
            return throwError(errMessage);
        }
        return throwError(error || 'Node.js server error');
    }

}
